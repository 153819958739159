import { Box, Container } from '@material-ui/core';
import { Link } from 'gatsby';
import React from 'react';
import LinearCTAButton from 'src/components/LinearCTAButton';
import styles from './SectionPayment.module.scss';

export default function SectionPayment() {
  return (
    <section>
      <Container className={styles.containerTop}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <h3 className={styles.title}>ペイディを始めてみませんか</h3>
          <p className={styles.text}>
            お申し込みの方法や導入についてもっと詳しく聞きたいという方は、こちらからご相談ください。業種にあった専門の担当者が対応いたします。
          </p>
          <Link className={styles.btn} to="/merchant/contact_us/">
            お気軽にご相談ください
          </Link>
        </Box>
      </Container>
      <Container className={styles.containerBottom}>
        <Box
          display="flex"
          gridGap={32}
          justifyContent="center"
          flexWrap="wrap"
        >
          <LinearCTAButton
            text="導入のお申し込みはこちら"
            to="/merchant/application/#documents"
          />
          <LinearCTAButton text="資料請求" to="/merchant/materials/sales/" />
        </Box>
      </Container>
    </section>
  );
}
