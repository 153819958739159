// @flow
//Core
import React, { useState, useEffect, useCallback } from 'react';
import { Grid, Box, Chip } from '@material-ui/core';
import classNames from 'classnames';
import get from 'lodash.get';

//Local
import { itemsStyles } from 'src/components/Column/News/styles';
import { filterCardsForPublish } from 'src/components/Column/utils';
import { PaginationColor } from 'src/components';
import ColumnPostItem from 'src/components/Column/News/items/ColumnPostItem';

const ItemsSec = ({
  tags,
  items: initialItems,
  loadBy = 9,
  selectedTag,
  tagsClasses,
}) => {
  const styles = itemsStyles({});
  const [items, setItems] = useState(initialItems);
  const [filterBy, setFilterBy] = useState(selectedTag);
  const [page, setPage] = useState(0);
  const [filteredItems, setFilteredItem] = useState(
    filterCardsForPublish(items)
  );

  const handleTagClick = (id, tagTitle) => {
    setFilterBy(id);
    window.location.hash = tagTitle;
  };

  useEffect(() => {
    const from = page * loadBy;
    const to = from + loadBy;

    setFilteredItem(filterCardsForPublish(items).slice(from, to));
  }, [page, items]);

  const filterItemsByTag = useCallback(
    (data) => {
      console.log('filterItemsByTag', data);

      return data.filter((item) =>
        filterBy
          ? get(item, 'fields.tags', []).find((tag) => tag.sys.id === filterBy)
          : initialItems
      );
    },
    [filterBy, initialItems]
  );

  useEffect(() => {
    setItems(filterItemsByTag(initialItems));
    setPage(0);
  }, [filterBy, initialItems]);

  useEffect(() => {
    const tag = tags.find((t) => t.fields.title === selectedTag);

    if (!tag) {
      setFilterBy(null);
    } else if (tag.sys.id !== filterBy) {
      setFilterBy(tag.sys.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTag, tags, filterBy]);

  const tagsJSX = [
    {
      fields: { title: 'すべての記事' },
      sys: { id: null },
    },
    ...tags,
  ].map((item, index) => (
    <Chip
      label={item.fields.title}
      onClick={() => handleTagClick(item.sys.id, item.fields.title)}
      key={index}
      clickable
      color="secondary"
      className={classNames(styles.tag, {
        [styles.activeTag]: item.sys.id === filterBy,
      })}
    />
  ));

  const itemsJSX = filteredItems.map((item, index) => (
    <ColumnPostItem
      itemUrl={
        item.type === 'CaseStudy'
          ? `/merchant/case/${get(item, 'id')}`
          : `/merchant/column/article/${get(item, 'id')}`
      }
      title={get(item, 'title')}
      subtitle={get(item, 'subtitle')}
      tags={get(item, 'tags', [])
        .map((tag) => get(tag, 'fields.title'))
        .join(', ')}
      previewImage={get(item, 'previewImage.fields.file.url')}
      index={index}
      key={index}
    />
  ));

  return (
    <section className={styles.section}>
      <Box className={classNames(styles.tags, tagsClasses)}>{tagsJSX}</Box>
      <Grid container spacing={3} zeroMinWidth item>
        {itemsJSX}
      </Grid>

      <PaginationColor
        pageCount={Math.ceil(initialItems.length / loadBy)}
        page={page}
        gotoPage={(selected) => setPage(selected)}
      />
    </section>
  );
};

ItemsSec.defaultProps = {
  items: [],
  handleCustomLoadMore: undefined,
  isLoaded: undefined,
  loadBy: 9,
  selectedTag: null,
  centeredCardBackground: true,
  tagsClasses: null,
};

export default ItemsSec;
