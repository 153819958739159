/* eslint-disable jsx-a11y/media-has-caption, react/no-danger, jsx-a11y/no-noninteractive-element-interactions */
// @flow
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash.get';
import {
  ItemsSec,
  ItemsSecMobile,
  SectionPayment,
} from 'src/components/Merchant/Column';
import { SITE_METADATA } from '../../constants';
import Hero from '../../components/Merchant/shared/Hero';
import styles from '../../styles/pages/column.module.scss';
import Layout from '../../components/Layout/Layout';
import Bg from '../../images/case_study.svg';
import {
  getColumnArticles,
  getColumnHighlight,
} from '../../redux/merchant/actions';
import { PreviewSec } from '../../components/Column/News';

export default function Column({ location }) {
  const tags = decodeURIComponent(location.hash.replace('#', ''));
  const columnHighlight = useSelector(
    (state) => state.merchantPages.columnHighlight
  );
  const columnArticles = useSelector((state) => state.merchantPages.column);
  const dispatch = useDispatch();

  // Did mount
  useEffect(() => {
    dispatch(getColumnHighlight());
    dispatch(getColumnArticles());
  }, [dispatch]);

  return (
    <Layout
      isMerchant
      SEOProps={{
        title: SITE_METADATA.column.title,
        description: SITE_METADATA.column.description,
      }}
    >
      <Hero title="お知らせ" bg={Bg} bgSp={Bg} />
      <PreviewSec
        title={get(columnHighlight, 'pageTitle')}
        subtitle={get(columnHighlight, 'pageSubtitle')}
        card={{
          ...get(columnHighlight, 'primaryArticle.fields', {}),
          imgUrl: get(
            columnHighlight,
            'primaryArticle.fields.previewImage.fields.file.url'
          ),
          url: `/merchant/column/article/${get(
            columnHighlight,
            'primaryArticle.sys.id'
          )}`,
          tags: get(columnHighlight, 'primaryArticle.fields.tags', []).map(
            (item) => item.fields.title
          ),
        }}
        cardClasses={styles.previewSec}
        isTransparent
      />
      <div className={styles.container}>
        <div className={styles.pc}>
          <ItemsSec
            tagsClasses={styles.tagsPc}
            title={get(columnHighlight, 'secondSectionTitle')}
            tags={get(columnHighlight, 'tags', [])}
            items={columnArticles}
            selectedTag={tags}
          />
        </div>
        <div className={styles.sp}>
          <ItemsSecMobile
            title={get(columnHighlight, 'secondSectionTitle')}
            tags={get(columnHighlight, 'tags', [])}
            items={columnArticles}
            selectedTag={tags}
          />
        </div>
      </div>
      <SectionPayment />
    </Layout>
  );
}
