// @flow
//Core
import React, { useState, useEffect, useCallback } from 'react';
import { Grid, Box, Chip, Button } from '@material-ui/core';
import classNames from 'classnames';
import get from 'lodash.get';

//Local
import { itemsStyles } from 'src/components/Column/News/styles';
import { filterCardsForPublish } from 'src/components/Column/utils';
import ColumnPostItem from 'src/components/Column/News/items/ColumnPostItem';

const ItemsSecMobile = ({
  tags,
  items: initialItems,
  loadBy = 3,
  selectedTag,
}) => {
  const styles = itemsStyles({});
  const [items, setItems] = useState(initialItems);
  const [filterBy, setFilterBy] = useState(selectedTag);
  const filteredItems = filterCardsForPublish(items);
  const [loadedCount, setLoadedCount] = useState(loadBy);
  const isLoaded = loadedCount >= initialItems.length;

  const handleTagClick = (id, tagTitle) => {
    setFilterBy(id);
    window.location.hash = tagTitle;
  };

  const handleLoadMoreClick = () => {
    setLoadedCount(
      loadedCount + loadBy <= initialItems.length
        ? loadedCount + loadBy
        : initialItems.length
    );
  };

  const filterLoadedItems = (data) => data.slice(0, loadedCount);

  const filterItemsByTag = useCallback(
    (data) =>
      data.filter((item) =>
        filterBy
          ? get(item, 'fields.tags', []).find((tag) => tag.sys.id === filterBy)
          : initialItems
      ),
    [filterBy, initialItems]
  );

  useEffect(() => {
    setItems(filterLoadedItems(filterItemsByTag(initialItems)));
  }, [filterBy, initialItems, loadedCount]);

  useEffect(() => {
    const tag = tags.find((t) => t.fields.title === selectedTag);

    if (!tag) {
      setFilterBy(null);
    } else if (tag.sys.id !== filterBy) {
      setFilterBy(tag.sys.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTag, tags, filterBy]);

  const tagsJSX = [
    {
      fields: { title: 'すべての記事' },
      sys: { id: null },
    },
    ...tags,
  ].map((item, index) => (
    <Chip
      label={item.fields.title}
      onClick={() => handleTagClick(item.sys.id, item.fields.title)}
      key={index}
      clickable
      color="secondary"
      className={classNames(styles.tag, {
        [styles.activeTag]: item.sys.id === filterBy,
      })}
    />
  ));

  const itemsJSX = filteredItems.map((item, index) => (
    <ColumnPostItem
      itemUrl={
        item.type === 'CaseStudy'
          ? `/merchant/case/${get(item, 'id')}`
          : `/merchant/column/article/${get(item, 'id')}`
      }
      title={get(item, 'title')}
      subtitle={get(item, 'subtitle')}
      tags={get(item, 'tags', [])
        .map((tag) => get(tag, 'fields.title'))
        .join(', ')}
      previewImage={get(item, 'previewImage.fields.file.url')}
      index={index}
      key={index}
    />
  ));

  return (
    <section className={styles.section}>
      <Box className={styles.tags}>{tagsJSX}</Box>
      <Grid container zeroMinWidth item>
        {itemsJSX}
      </Grid>

      {!isLoaded && (
        <Box textAlign="center" className={styles.loadMoreBtnContainer}>
          <Button
            className={styles.loadMoreBtn}
            variant="outlined"
            color="primary"
            onClick={handleLoadMoreClick}
          >
            もっと見る
          </Button>
        </Box>
      )}
    </section>
  );
};

ItemsSecMobile.defaultProps = {
  items: [],
  handleCustomLoadMore: undefined,
  isLoaded: undefined,
  loadBy: 4,
  selectedTag: null,
  centeredCardBackground: true,
};

export default ItemsSecMobile;
